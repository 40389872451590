import React, { useState, useEffect, useRef, FormEvent } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { buildUrl } from "../components/namedlink/NamedLink";

/**
 * Simple form allowing you to choose two pages by URL or id to compare.
 */
export default function ChooseCompareMapsPage() {
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const handleSubmit = (result: {baseId: string, modId: string}) => {
    navigate(buildUrl("comparePage", result))
  };
  const baseMapIds = queryParams.getAll("baseMapId")
  const defaultBase = baseMapIds.length > 0 ? baseMapIds[0] : "";

  return <>
    <h2>Compare maps</h2>
    <p>Paste the id or URLs of two maps to highlight the differences.</p>
    <CompareForm onSubmit={handleSubmit} defaultBase={defaultBase} />
  </>;
}

type CompareFormProps = {
  onSubmit: (result: {baseId: string, modId: string}) => void,
  defaultBase: string
}
function CompareForm(props: CompareFormProps) {
  const location = useLocation();
  const baseRef = useRef<HTMLInputElement>(null);
  const modRef = useRef<HTMLInputElement>(null);
  const extractId = (value: string) => {
    if (value.startsWith("http")) {
      const maybeIndex = value.indexOf("/map/");
      if (maybeIndex) {
        return value.slice(maybeIndex + 5);
      } else {
        return null;
      }
    } else {
      return value;
    }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const maybeBaseId = extractId(baseRef.current?.value as string);
    const maybeModId = extractId(modRef.current?.value as string);

    if (!maybeBaseId || !maybeModId) {
      // TODO: Validation of some sort
      console.error("Invalid input");
      return;
    }

    props.onSubmit({
      baseId: maybeBaseId,
      modId: maybeModId,
    })
  };

  useEffect(() => {
    const ref = props.defaultBase !== "" ? modRef : baseRef;
    if (ref.current) {
      ref.current.focus();
    }
    if (baseRef.current) {
      baseRef.current.value = props.defaultBase;
    }
  }, [location]);

  return (
    <>
      <form className="form form--stacked" onSubmit={handleSubmit}>
        <label htmlFor="baseMap">Base map:</label>
        <input id="baseMap" type="text" className="form__input" ref={baseRef} required={true} />
        <p className="form__longdesc">The map you want to treat as the original.</p>

        <label htmlFor="baseMap">Modified map:</label>
        <input id="baseMap" type="text" className="form__input" ref={modRef} required={true} />
        <p className="form__longdesc">The map you want to treat as the modified version.</p>

        <div className="form__actions">
          <button className="button">Compare</button>
        </div>
      </form>
    </>
  );
}
