import React, { ReactNode, useState, useReducer } from 'react';
import "./FlashMessage.css"

interface Props {
  visible: boolean,
  children: ReactNode
}

export function FlashMessage(props: Props) {
  const classes = props.visible ? " flashmessage--visible" : "";
  return <>
    <div className={`flashmessage${classes}`}>
      {props.children}
    </div>
  </>;
}

type FlashMessageResult = {
  flashMessageElement: ReactNode,
  setFlashMessage: (message: ReactNode, timeout?: number) => void
};
type FlashMessageState = {
  message: ReactNode | null,
  oldMessage: ReactNode | null,
  timeoutHandle: number | null
};

/**
 * Hook for building a FlashMessage component instance and a function for setting its contents
 * (with timeout).
 */
export function useFlashMessage(): FlashMessageResult {
  type ReducerData = {
    message: ReactNode | null,
    timeoutHandle: number | null
  };
  const setter = (state: FlashMessageState, data: ReducerData) => {
    const {message, timeoutHandle} = data;
    if (state.timeoutHandle) {
      window.clearTimeout(state.timeoutHandle);
    }

    return {
      message,
      oldMessage: state.message || message,
      timeoutHandle
    };
  };

  const [flashMessage, setFlashMessageInner] = useReducer(
    setter,
    {
      message: null,
      oldMessage: null,
      timeoutHandle: null
    }
  );

  return {
    flashMessageElement: (
      <FlashMessage visible={!!flashMessage.message}>{flashMessage.message || flashMessage.oldMessage}</FlashMessage>
    ),
    setFlashMessage: (message, timeout) => {
      let timeoutHandle = null;
      if (timeout) {
        timeoutHandle = window.setTimeout(() => {
          setFlashMessageInner({message: null, timeoutHandle: null})
        }, timeout);
      }
      setFlashMessageInner({message, timeoutHandle});
    }
  }
}
