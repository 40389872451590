/**
 * Simple component extending react-router-dom to provide named routes. Assumes there's only a
 * single routing tree per application (uses a global singleton).
 */
import React, { ReactNode } from 'react';
import { Route, Link } from "react-router-dom";

interface RouteInfo {
    path: string,
    element: ReactNode,
    name?: string,
    children?: RouteInfo[]
};

let populatedMappings = false;
let nameMappings: {[key: string]: string} = {};

export function buildRoutes(routes: RouteInfo[]): ReactNode {
  const buildRoutes = (routeList: RouteInfo[], pathPrefix: string) => {
    return routeList.map((route, i) => {
      const fullPath = pathPrefix + route.path;

      if (route.name) {
        nameMappings[route.name] = fullPath;
        populatedMappings = true;
      }

      let children = <></>;
      if (route.children) {
        children = <>
          {buildRoutes(
            route.children,
            fullPath
          )}
        </>;
      }

      // key is only here to keep React quiet, we're not going to change the routes
      return <Route path={route.path} element={route.element} key={pathPrefix + i}>{children}</Route>;
    });
  };

  return <>{buildRoutes(routes, "")}</>;
}

type ParamDict = {[key: string]: string};

export function buildUrl(name: string, params?: ParamDict, queryParams?: ParamDict): string {
    if (!populatedMappings) {
      throw "Must call buildRoutes before using this function";
    }

    const maybeResult = nameMappings[name];
    if (maybeResult) {
      let baseUrl = maybeResult.split("/").map((bit) => {
          if (bit.startsWith(":")) {
            if (params && params[bit.slice(1)]) {
              return params[bit.slice(1)];
            } else {
              throw `Parameter ${bit} not given in params argument`;
            }
          } else {
            return bit;
          }
      }).join("/");

      if (queryParams) {
        const query = new URLSearchParams();
        for (const [key, val] of Object.entries(queryParams)) {
          query.append(key, val);
        }
        baseUrl += "?" + query.toString();
      }

      return baseUrl;
    } else {
      throw `Could not find mapping for name ${name}`;
    }
}

interface NamedLinkProps {
  name: string,
  params?: ParamDict,
  queryParams?: ParamDict,
  children: ReactNode
}
export function NamedLink(props: NamedLinkProps) {
  return <Link to={buildUrl(props.name, props.params, props.queryParams)}>{props.children}</Link>;
}
