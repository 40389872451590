import React, { useState, useEffect, useRef, FormEvent, ReactElement } from 'react';
import { useNavigate } from "react-router-dom";
import { PassThrough } from 'stream';
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import {
  MapId,
  ReasonItem,
  BeliefMap,
  SupportLevel,
  BeliefLevel,
  updateReason,
  deleteReason,
  parseMapId
} from "../../features/beliefMap/beliefMapSlice";
import SalienceIcon from "../salienceicon/SalienceIcon";
import { buildUrl } from "../namedlink/NamedLink";
import "./ReasonList.css";

interface Props {
  beliefMap: BeliefMap,
  editable: boolean
}

export default function ReasonList(props: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const mapId = props.beliefMap.id;

  const [showEditForm, setShowEditForm] = useState(false);
  const resetEmitter = buildEventEmitter();

  const handleSubmit = (reasonData: ReasonFormData) => {
    dispatch(updateReason({
      mapId: mapId,
      reasonId: null,
      title: reasonData.title,
      belief: reasonData.belief,
      support: reasonData.support,
      relevance: reasonData.relevance,
    }));

    resetEmitter.trigger();
    if (mapId.inputMapId !== mapId.draftMapId) {
      navigate(buildUrl("mapPage", {mapId: mapId.draftMapId}));
    }
  };
  const formSection = !props.editable ?
    <></> :
    (
      showEditForm ?
        <ReasonListItemForm item={null} onSubmit={handleSubmit} resetFunc={resetEmitter.listener} onCancel={() => setShowEditForm(false)} /> :
        <button className="button" onClick={() => setShowEditForm(true)}>Add item</button>
    );

  return (
    <section className="reasonlist">
      {props.beliefMap.reasons.length > 0 ?
        (
          <ol className="reasonlist-list">
          {props.beliefMap.reasons.map(item => <ReasonListItem item={item} beliefMap={props.beliefMap} key={item.id} editable={props.editable} />)}
          </ol>
        ) : <p>No reasons included.</p>}
    {formSection}
    </section>
  );
}

function ReasonListItem(props: {item: ReasonItem, beliefMap: BeliefMap, editable: boolean}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const mapId = props.beliefMap.id;
  const [showEditForm, setShowEditForm] = useState(false);

  const reasonData = {
    title: props.item.title,
    belief: props.item.belief,
    support: props.item.support,
    relevance: props.item.relevance
  };

  const maybeRedirect = () => {
    if (mapId.inputMapId !== mapId.draftMapId) {
      navigate(buildUrl("mapPage", {mapId: mapId.draftMapId}));
    }
  };

  const handleSubmit = (reasonData: ReasonFormData) => {
    dispatch(updateReason({
      mapId: mapId,
      reasonId: props.item.id,
      title: reasonData.title,
      belief: reasonData.belief,
      support: reasonData.support,
      relevance: reasonData.relevance,
    }));

    setShowEditForm(false);
    maybeRedirect();
  };

  const handleDelete= () => {
    dispatch(deleteReason({
      mapId: mapId,
      reasonId: props.item.id
    }));
    maybeRedirect();
  }

  const formElements = !props.editable ?
    <></> :
    (showEditForm ?
      <ReasonListItemForm item={reasonData} onSubmit={handleSubmit} onCancel={() => setShowEditForm(false)} onDelete={handleDelete} /> :
      <div className="reasonlist-item__buttons">
        <button className="button button--small" onClick={() => setShowEditForm(true)}>Edit item</button>
      </div>
    );

  return (
    <li className="reasonlist-item">
      {showEditForm ?
        <></> :
        <>
          <h2 className="reasonlist-item__header">{reasonData.title}</h2>
          <div className="reasonlist-item__salienceicons">
            <SalienceIcon type="belief" level={reasonData.belief} showLabel={false} />
            <SalienceIcon type="support" level={reasonData.support} showLabel={false} />
          </div>
          {reasonData.relevance && <p className="reasonlist-item__relevance">{reasonData.relevance}</p>}
        </>}
      {formElements}
    </li>
  );
}

type ReasonFormData = {
  title: string,
  belief: BeliefLevel | null,
  support: SupportLevel | null,
  relevance: string | null
}
type ReasonListItemFormProps = {
  showstate?: "hidden" | "showing" | "shown",
  item: null | ReasonFormData,
  onSubmit: (result: ReasonFormData) => void,
  onDelete?: () => void,
  onCancel?: () => void,
  resetFunc?: (f: () => void) => void
}

function ReasonListItemForm(props: ReasonListItemFormProps) {
  const titleRef = useRef<HTMLInputElement>(null);
  const beliefRef = useRef<HTMLSelectElement>(null);
  const supportRef = useRef<HTMLSelectElement>(null);
  const relevanceRef = useRef<HTMLTextAreaElement>(null);

  const [doFocus, setDoFocus] = useState(true);

  useEffect(() => {
    if (doFocus && titleRef.current) {
      titleRef.current.focus();
      setDoFocus(false);
    }
  });

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!(titleRef.current && supportRef.current && relevanceRef.current && beliefRef.current)) {
      return;
    }

    props.onSubmit({
      title: titleRef.current.value,
      belief: beliefRef.current.value === "" ? null : beliefRef.current.value as BeliefLevel,
      support: supportRef.current.value === "" ? null : supportRef.current.value as SupportLevel,
      relevance: relevanceRef.current.value
    });
  };
  const onDelete = props.onDelete || (() => {console.error("Unhandled delete button");});
  const onCancel = props.onCancel || (() => {console.error("Unhandled cancel button");});

  if (props.resetFunc) {
    props.resetFunc(() => {
      for (let ref of [titleRef, beliefRef, supportRef, relevanceRef]) {
        if (ref.current) {
          ref.current.value = "";
        }
      }
      if (titleRef.current) {
        titleRef.current.focus();
      }
    });
  }

  return (
    <form className="form form--stacked" onSubmit={onSubmit}>
      <label htmlFor="reasonFormTitle">Title:</label>
      <input id="reasonFormTitle" type="text" className="form__input" ref={titleRef} required={true} defaultValue={props.item?.title} />
      <p className="form__longdesc">The one line description of this belief</p>

      <label htmlFor="reasonFormSupport">Support level:</label>
      <select id="reasonFormSupport" className="form__input" ref={supportRef} defaultValue={props.item?.support || ""}>
        <option value="">Select...</option>
        <option value="strong support">Strongly supports claim</option>
        <option value="support">Supports claim</option>
        <option value="neutral">Irrelevant to claim</option>
        <option value="disconfirm">Disconfirms claim</option>
        <option value="strong disconfirm">Strongly disconfirms claim</option>
      </select>
      <p className="form__longdesc">If this reason were true, how strongly would it support or disconfirm the claim?</p>

      <label htmlFor="reasonFormrelevance">Relevance:</label>
      <textarea id="reasonFormrelevance" className="form__input" name="relevance" ref={relevanceRef}  defaultValue={props.item?.relevance || ""} />
      <p className="form__longdesc">An explanation of why you think this reason would confirm, disconfirm, or be irrelevant to supporting the main claim.</p>

      <label htmlFor="reasonFormBelief">Belief level:</label>
      <select id="reasonFormBelief" className="form__input" ref={beliefRef} defaultValue={props.item?.belief || ""}>
        <option value="">Select...</option>
        <option value="strong agree">Strongly agree</option>
        <option value="agree">Agree</option>
        <option value="neutral">Not sure if agree</option>
        <option value="disagree">Disagree</option>
        <option value="strong disagree">Strongly disagree</option>
      </select>
      <p className="form__longdesc">How much do you believe this reason is true?</p>

      <div className="form__actions">
        <button className="button">{props.item ? "Update item" : "Add new item"}</button>
        <button className="button button--secondary" onClick={(e) => {onCancel();e.preventDefault()}}>Cancel</button>
        {props.item && <button className="button button--warning" style={{float:"right"}} onClick={(e) => {onDelete();e.preventDefault()}}>Delete item</button>}
      </div>
    </form>
  );
}

/**
 * Builds an object containing a 'trigger' and a 'listener' function. When the trigger is executed
 * it will execute the function that has been registered with the listener.
 */
function buildEventEmitter() {
  let listener: (() => void) | null = null;
  return {
    listener: (f: () => void) => {
      listener = f;
    },
    trigger: () => {
      if (listener) {
        listener();
      }
    }
  };
}

type DiffedReasonListItem = {
  id: string,
  itemState: string,
  title: string | ReactElement,
  relevance: null | string | ReactElement,
  belief: BeliefLevel | null,
  support: SupportLevel | null,
  baseBelief: BeliefLevel | null,
  baseSupport: SupportLevel | null,
  actionElements?: ReactElement,
};
interface DiffedReasonListProps {
  items: DiffedReasonListItem[]
}

/**
 * Variant of the ReasonList component for showing the difference between two
 * reason lists
 */
export function DiffedReasonList(props: DiffedReasonListProps) {
  const reasonListItems = props.items.map((item) => {
    const extraClasses = item.itemState === "deleted" ?
      " reasonlist-item--deleted" :
      item.itemState === "added" ? " reasonlist-item--added" : "";
    const beliefChanged = item.baseBelief !== item.belief;
    const supportChanged = item.baseSupport !== item.support;

    return <li className={`reasonlist-item${extraClasses}`} key={item.id}>
      <h2 className="reasonlist-item__header">{item.title}</h2>
      <div className="reasonlist-item__salienceicons">
        <SalienceIcon type="belief" level={item.baseBelief} extraClasses={beliefChanged ? "reasonlist-item__salienceicons--deleted" : ""} showLabel={false} />
        <SalienceIcon type="support" level={item.baseSupport} extraClasses={supportChanged ? "reasonlist-item__salienceicons--deleted" : ""} showLabel={false} />
      </div>
      <div className="reasonlist-item__salienceicons--modified">
        <SalienceIcon type="belief" level={beliefChanged ? item.belief : null} extraClasses={beliefChanged ? "reasonlist-item__salienceicons--added" : ""} showLabel={false} />
        <SalienceIcon type="support" level={supportChanged ? item.support : null} extraClasses={supportChanged ? "reasonlist-item__salienceicons--added" : ""} showLabel={false} />
      </div>
      <p className="reasonlist-item__relevance">{item.relevance}</p>
      {item.actionElements && <div className="reasonlist-item__buttons">{item.actionElements}</div>}
    </li>;
  });

  return <section className="reasonlist">
    <ol className="reasonlist-list">
      {reasonListItems}
    </ol>
  </section>;
}
