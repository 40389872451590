import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from './App';
import BeliefMapPage from './pages/BeliefMapPage';
import ChooseCompareMapsPage from './pages/ChooseCompareMapsPage';
import CompareMapsPage from './pages/CompareMapsPage';
import HelpPage from './pages/HelpPage';
import { store } from './app/store';
import { Provider } from 'react-redux';
import { buildRoutes } from './components/namedlink/NamedLink';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          {
            buildRoutes([
              {
                name: "root",
                path: "/",
                element: <App />,
                children: [
                  {
                    name: "mapPage",
                    path: "map/:mapId",
                    element: <BeliefMapPage />
                  },
                  {
                    name: "chooseComparePage",
                    path: "compare",
                    element: <ChooseCompareMapsPage />
                  },
                  {
                    name: "comparePage",
                    path: "compare/:baseId/:modId",
                    element: <CompareMapsPage />
                  },
                  {
                    name: "helpPage",
                    path: "help",
                    element: <HelpPage />
                  },
                ]
              },
            ])
          }
        </Routes>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
