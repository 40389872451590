import React, { useState, useEffect, useRef, FormEvent, ReactElement } from "react";
import { Params, useParams } from "react-router-dom";
import { useAppContext } from "../App";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { AppDispatch } from "../app/store";
import {
  BeliefMap,
  ReasonItem,
  loadMap,
  parseMapId,
  selectBeliefMap,
  useBeliefMap,
  overwriteDraft,
  updateBelief,
  updateReason,
  deleteReason
} from "../features/beliefMap/beliefMapSlice";
import { DiffedReasonList } from "../components/reasonlist/ReasonList";
import { NamedLink } from "../components/namedlink/NamedLink";
import Diff from "../utils/diff";
import "./CompareMapsPage.css";
import SalienceIcon from "../components/salienceicon/SalienceIcon";

function fetchMaps(dispatch: AppDispatch, params: Params<string>) {
  const maybeBaseId = params.baseId || "error";
  const maybeModId = params.modId || "error";
  const maybeBaseMapId = parseMapId(maybeBaseId);
  const maybeModMapId = parseMapId(maybeModId);
  const maybeBaseMap = useBeliefMap(maybeBaseMapId);
  const maybeModMap = useBeliefMap(maybeModMapId);

  let isLoading = maybeBaseMap === "empty" || maybeModMap === "empty";

  if (isLoading) {
    return "loading";
  } else if (maybeBaseMap === "error" || maybeModMap === "error") {
    return "error";
  } else {
    return [maybeBaseMap as BeliefMap, maybeModMap as BeliefMap];
  }
}

function diffToReact(base: string, modified: string): {different: boolean, render: ReactElement} {
  const diff = new Diff();
  const difference = diff.main(base, modified);
  diff.cleanupSemantic(difference);
  const elms = difference.map((item, i) => {
    switch (item[0]) {
      case -1:
        return <del key={i}>{item[1]}</del>;
      case 0:
        return <React.Fragment key={i}>{item[1]}</React.Fragment>;
      case 1:
        return <ins key={i}>{item[1]}</ins>;
    }
    return <></>;
  });

  return {
    different: base !== modified,
    render: <>{elms}</>
  };
}

interface ComparisonComponentProps {
  baseMap: BeliefMap,
  modMap: BeliefMap
}

function ComparisonComponent(props: ComparisonComponentProps) {
  const dispatch = useAppDispatch();
  const appContext = useAppContext();

  appContext.useWindowTitle("Compare maps - Belief Mapper");

  const handleUndo = () => {
    const handleUndoChange = async () => {
      await overwriteDraft(props.baseMap.id, props.baseMap, dispatch);

      appContext.setFlashMessage(null);
    };

    appContext.setFlashMessage(
      (
        <p>
          Draft updated <button className="button" onClick={handleUndoChange}>Undo</button>
        </p>
      ),
      5000
    );
  };
  const handleMerge = () => {
    handleUndo();
    dispatch(updateBelief({
      mapId: props.baseMap.id,
      title: props.modMap.title,
      description: props.modMap.description
    }));
  }

  const link = (id: string) => {
    const len = 10;
    let content = id;
    if (id.length > len) {
      content = id.slice(0, len) + "...";
    }

    return <NamedLink name="mapPage" params={{mapId: id}}>{content}</NamedLink>;
  }
  const titleDiff = diffToReact(props.baseMap.title, props.modMap.title);
  const descriptionDiff = diffToReact(props.baseMap.description || "", props.modMap.description || "");
  const baseMapIsDraft = props.baseMap.id.inputIsDraft;
  return <>
    <div className="comparepage">
      <p>Comparing base {link(props.baseMap.id.inputMapId)} to modified {link(props.modMap.id.inputMapId)}. {!baseMapIsDraft && <>To merge changes, <NamedLink name="comparePage" params={{baseId: props.baseMap.id.draftMapId, modId: props.modMap.id.inputMapId}}>swap the base map to its draft</NamedLink>.</>}</p>
    <h2>{titleDiff.render}</h2>
    <p>{descriptionDiff.render}</p>
    {
      baseMapIsDraft && (titleDiff.different || descriptionDiff.different) &&
        <button className="button" onClick={handleMerge}>Merge text changes to draft</button>
    }

    <h3>Reasons</h3>
    <ReasonListDiffComponent baseMap={props.baseMap} modMap={props.modMap} undoHandler={handleUndo} />
    </div>
  </>;
}


interface ReasonListDiffComponentProps {
  baseMap: BeliefMap,
  modMap: BeliefMap,
  undoHandler: () => void
}
function ReasonListDiffComponent(props: ReasonListDiffComponentProps) {
  const dispatch = useAppDispatch();
  const baseMapIsDraft = props.baseMap.id.inputIsDraft;
  type EvMap = {[key: string]: ReasonItem};
  const modReasonMap: EvMap = props.modMap.reasons.reduce((acc, curr) => {
    acc[curr.id] = curr;
    return acc;
  }, {} as EvMap);
  const usedMap: {[key: string]: boolean} = {};
  const handleUndo = props.undoHandler;

  let reasonItems = props.baseMap.reasons.map((item) => {
    const baseTitle = item.title;
    const baseRelevance = item.relevance || "";
    if (modReasonMap[item.id]) {
      usedMap[item.id] = true;
      const modReason = modReasonMap[item.id];
      const modTitle = modReason.title;
      const modRelevance = modReason.relevance || "";
      const baseBelief = item.belief;
      const modBelief = modReason.belief;
      const baseSupport = item.support;
      const modSupport = modReason.support;

      const titleDiff = diffToReact(baseTitle, modTitle);
      const relevanceDiff = diffToReact(baseRelevance, modRelevance);

      const handleTitleMerge = () => {
        handleUndo();
        dispatch(updateReason({
          mapId: props.baseMap.id,
          reasonId: item.id,
          title: modTitle,
          belief: baseBelief,
          support: baseSupport,
          relevance: baseRelevance
        }));
      };
      const handleSupportRelevanceMerge = () => {
        handleUndo();
        dispatch(updateReason({
          mapId: props.baseMap.id,
          reasonId: item.id,
          title: baseTitle,
          belief: baseBelief,
          support: modSupport,
          relevance: modRelevance
        }));
      };

      let buttons = [];
      if (props.baseMap.id.inputIsDraft) {
        if (titleDiff.different) {
          buttons.push(
            <button className="button" onClick={handleTitleMerge} key="title">
              Merge title changes to draft
            </button>
          );
        }
        if (relevanceDiff.different || baseSupport !== modSupport) {
          buttons.push(
            <button className="button" onClick={handleSupportRelevanceMerge} key="support">
              Merge support and reason changes to draft
            </button>
          );
        }
      }

      return {
        "id": item.id,
        "itemState": "mixed",
        "title": titleDiff.render,
        "relevance": relevanceDiff.render,
        "belief": modBelief,
        "support": modSupport,
        "baseBelief": baseBelief || null,
        "baseSupport": baseSupport || null,
        "actionElements": (buttons.length > 0) ? <>{buttons}</> : undefined
      };
    } else {
      const handleDelete = () => {
        handleUndo();
        dispatch(deleteReason({
          mapId: props.baseMap.id,
          reasonId: item.id
        }));
      };

      const maybeActionElements = props.baseMap.id.inputIsDraft ?
        <button className="button" onClick={handleDelete}>Delete reason from draft</button> : undefined;

      return {
        "id": item.id,
        "itemState": "deleted",
        "title": baseTitle,
        "relevance": item.relevance,
        "belief": item.belief,
        "support": item.support,
        "baseBelief": item.belief,
        "baseSupport": item.support,
        "actionElements": maybeActionElements
      };
    }
  });

  props.modMap.reasons.forEach((item) => {
    if (usedMap[item.id]) {
      return;
    }

    const handleAddReason = () => {
      handleUndo();
      dispatch(updateReason({
        mapId: props.baseMap.id,
        reasonId: item.id,
        title: item.title,
        belief: null,
        support: item.support,
        relevance: item.relevance
      }));
    };
    const maybeActionElements = props.baseMap.id.inputIsDraft ?
      <button className="button" onClick={handleAddReason}>Add reason to draft</button> : undefined;

    reasonItems.push({
      "id": item.id,
      "itemState": "added",
      "title": item.title,
      "relevance": item.relevance,
      "belief": item.belief,
      "support": item.support,
      "baseBelief": item.belief,
      "baseSupport": item.support,
      "actionElements": maybeActionElements
    });
  });

  return <DiffedReasonList items={reasonItems} />
}

export default function CompareMapsPage() {
  const params = useParams();
  const dispatch = useAppDispatch();
  const maybeMaps = fetchMaps(dispatch, params);

  if (maybeMaps === "loading") {
    return <>
      <h2>Loading maps...</h2>
    </>;
  } else if (maybeMaps === "error") {
    return <>
      <h2>Error loading maps</h2>
    </>;
  } else {
    const [baseMap, modMap] = maybeMaps;
    return <ComparisonComponent baseMap={baseMap} modMap={modMap} />
  }
}
