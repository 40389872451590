import React, { ReactNode, FunctionComponent, SVGProps } from 'react';
import "./SalienceIcon.css";
import { ReactComponent as CheckmarkIcon } from "./checkmark.svg";
import { ReactComponent as DownarrowIcon } from "./downarrow.svg";
import { ReactComponent as UparrowIcon } from "./uparrow.svg";
import { ReactComponent as CrossmarkIcon } from "./crossmark.svg";
import { ReactComponent as QuestionmarkIcon } from "./questionmark.svg";
import { ReactComponent as IrrelevantIcon } from "./irrelevant.svg";

type IconConstructor = FunctionComponent<SVGProps<SVGSVGElement> & { title?: string | undefined; }>;
type RenderMap = {[key: string]: [IconConstructor | null, IconConstructor | null, string]};
const beliefRenderMap: RenderMap = {
  "strong agree": [CheckmarkIcon, CheckmarkIcon, "Strongly agree"],
  "agree": [null, CheckmarkIcon, "Agree"],
  "neutral": [null, QuestionmarkIcon, "Neither agree nor disagree"],
  "disagree": [null, CrossmarkIcon, "Disagree"],
  "strong disagree": [CrossmarkIcon, CrossmarkIcon, "Strongly disagree"],
  "null": [null, null, "No agreement information"]
};
const supportRenderMap: RenderMap = {
  "strong support": [UparrowIcon, UparrowIcon, "Strongly supports"],
  "support": [null, UparrowIcon, "Supports"],
  "neutral": [null, IrrelevantIcon, "Has no relevance"],
  "disconfirm": [null, DownarrowIcon, "Disconfirms"],
  "strong disconfirm": [DownarrowIcon, DownarrowIcon, "Strongly disconfirms"],
  "null": [null, null, "No support information"]
};

interface Props {
  type: "belief" | "support",
  level: string | null,
  extraClasses?: string | null,
  showLabel: boolean
}

export default function SalienceIcon(props: Props) {
  const key = props.level || "null";
  const map = props.type === "belief" ? beliefRenderMap : supportRenderMap;
  const mapItem = map[key];
  let classes = "salienceicon ";
  if (props.showLabel) {
    classes += " salienceicon--showlabel";
  }

  if (props.extraClasses) {
    classes += " " + props.extraClasses;
  }

  const buildIcon = (arg: IconConstructor | null) => {
    if (arg) {
      return React.createElement(arg);
    } else {
      return <></>;
    }
  }
  const icons = <>
    {buildIcon(mapItem[0])}
    {buildIcon(mapItem[1])}
  </>;

  return (
    <div className={classes} title={mapItem[2]}>
      {icons}
      <div>{mapItem[2]}</div>
    </div>
  );
}
