import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk, AppDispatch } from "../../app/store";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { loadBeliefMap, persistBeliefMapDraft, publishBeliefMap } from "./beliefMapAPI";
import immerProduce from "immer";
import { SerializedBeliefMapv1 } from "./beliefMapAPITypes";

type UUID = string;

export type SupportLevel = "strong disconfirm" | "disconfirm" | "neutral" | "support" | "strong support";
export type BeliefLevel = "strong disagree" | "disagree" | "neutral" | "agree" | "strong agree";
export const beliefLevelMapping = [
  "strong disagree", "disagree", "neutral", "agree", "strong agree"
];
export const DEFAULT_MAP_ID = "draft.default";

export type ReasonItem = {
  id: UUID,
  title: string,
  relevance: string | null,
  belief: BeliefLevel | null,
  support: SupportLevel | null
};

export type MapId = {
  inputMapId: string,
  inputIsDraft: boolean,
  baseMapId: string,
  draftMapId: string
};

export interface BeliefMap {
  id: MapId,
  updatedAt: string,
  title: string,
  description: string | null,
  reasons: ReasonItem[]
}

type BeliefMapState = {
  maps: {
    [key: string]: BeliefMap | "error"
  }
};

const initialState: BeliefMapState = {
  maps: {}
};

/**
 * Verifies the format of the given mapId and extracts a dictionary of related mapIds
 */
export function parseMapId(mapId: string): MapId {
  const maybeBaseId = mapId.startsWith("draft.") ? mapId.slice(6) : mapId;

  if (!maybeBaseId.match(/^[0-9a-z-]+$/)) {
    throw `Map id ${maybeBaseId} does not match expected format`;
  }

  return {
    inputMapId: mapId,
    inputIsDraft: mapId !== maybeBaseId,
    baseMapId: maybeBaseId,
    draftMapId: `draft.${maybeBaseId}`
  }
}

function generateUUID(): string {
  // crypto.randomUUID() isn't available under insecure HTTP for some reason,
  // so use a crypto.getRandomValues approach from https://stackoverflow.com/a/2117523
  // instead!
  return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (c: any) =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

export const selectBeliefMap = (state: RootState, mapId: string): BeliefMap | "empty" | "error" => {
  return state.beliefMap.maps[mapId] || "empty";
};

type LoadMapResponse = {
  mapId: string,
  response: "error" | BeliefMap
}

export const loadMap = createAsyncThunk(
  "beliefMap/loadMap",
  async (mapId: string): Promise<LoadMapResponse> => {
    const response = await loadBeliefMap(mapId);
    if (response === "error") {
      return {
        mapId: mapId,
        response: "error"
      }
    } else {
      return {
        mapId: mapId,
        response: {
          id: parseMapId(mapId),
          updatedAt: response.published,
          title: response.data.title,
          description: response.data.description,
          reasons: response.data.reasons.map((item) => {
            return {
              id: item.id,
              title: item.title,
              relevance: item.relevance,
              // TODO: Validate these
              belief: item.belief as BeliefLevel,
              support: item.support as SupportLevel
            };
          })
        }
      };
    }
  }
);

function serialiseBeliefMap(beliefMap: BeliefMap, sortReason: boolean): SerializedBeliefMapv1 {
  const sortedReasons = [...beliefMap.reasons].sort((a, b) => {
    const supportsOrdering = ["strong support", "support", "strong disconfirm", "disconfirm", "neutral", null];
    const aSupport = supportsOrdering.indexOf(a.support);
    const bSupport = supportsOrdering.indexOf(b.support);

    if (aSupport === bSupport) {
      const aScore = a.belief ? beliefLevelMapping.indexOf(a.belief) : -100;
      const bScore = b.belief ? beliefLevelMapping.indexOf(b.belief) : -100;

      return bScore - aScore;
    } else {
      return aSupport - bSupport;
    }
  });

  return {
    version: "1.0",
    published: beliefMap.updatedAt,
    data: {
      title: beliefMap.title,
      description: beliefMap.description,
      reasons: sortedReasons,
    }
  };
}

function persistDraftMap(mapId: MapId, beliefMap: BeliefMap | null) {
  if (beliefMap) {
    persistBeliefMapDraft(
      mapId.draftMapId,
      serialiseBeliefMap(beliefMap, false)
    );
  } else {
    persistBeliefMapDraft(mapId.draftMapId, null);
  }
}

type ModifierFunction = (beliefMap: BeliefMap) => void;
// TODO: I don't think this should be called from a reducer because of the side effecting
// persistDraftMap. Instead create more functions like overwriteDraft that separate the redux
// and the impure stuff.
function modifyBeliefMap(state: BeliefMapState, mapId: MapId, f: ModifierFunction) {
  const updaterFunc = (beliefMap: BeliefMap) => {
    f(beliefMap);
    beliefMap.updatedAt = (new Date()).toISOString();
    return beliefMap;
  };

  if (state.maps[mapId.draftMapId] && state.maps[mapId.draftMapId] !== "error") {
    const beliefMap = state.maps[mapId.draftMapId];
    if (beliefMap === "error") {
      throw "Should not happen";
    }

    updaterFunc(beliefMap);
    persistDraftMap(mapId, beliefMap);
  } else if (state.maps[mapId.baseMapId]) {
    const beliefMap = state.maps[mapId.baseMapId];
    if (beliefMap === "error") {
      throw `Trying to edit broken belief map ${mapId}`;
    }
    // Use immer (which Redux uses anyway) to make an efficient clone of the base
    // map
    const result = immerProduce(beliefMap, updaterFunc);
    state.maps[mapId.draftMapId] = result;
    persistDraftMap(mapId, result);
  } else {
    throw `No draft or base map for ${mapId}`;
  }
}

type UpdateBeliefReducer = {
  mapId: MapId,
  title: string,
  description: string | null
}
type UpdateReasonReducer = {
  mapId: MapId,
  reasonId: string | null,
  title: string,
  support: SupportLevel | null,
  belief: BeliefLevel | null,
  relevance: string | null
}
type DeleteReasonReducer = {
  mapId: MapId,
  reasonId: string
}
export const beliefMapSlice = createSlice({
  name: "beliefMap",
  initialState,
  reducers: {
    updateBelief: (state: BeliefMapState, payload: PayloadAction<UpdateBeliefReducer>) => {
      const data = payload.payload;
      modifyBeliefMap(state, data.mapId, (beliefMap) => {
        beliefMap.title = data.title;
        beliefMap.description = data.description;
      });
    },
    updateReason: (state: BeliefMapState, payload: PayloadAction<UpdateReasonReducer>) => {
      const data = payload.payload
      modifyBeliefMap(state, data.mapId, (beliefMap) => {
        const uuid = data.reasonId || generateUUID();

        let foundItem = false;
        beliefMap.reasons = beliefMap.reasons.map((item) => {
          if (item.id === uuid) {
            item.title = data.title;
            item.belief = data.belief;
            item.relevance = data.relevance;
            item.support = data.support;
            foundItem = true;
          }

          return item;
        });
        if (!foundItem) {
          beliefMap.reasons.push({
            id: uuid,
            title: data.title,
            belief: data.belief,
            relevance: data.relevance,
            support: data.support
          });
        }
      });
    },
    deleteReason: (state: BeliefMapState, payload: PayloadAction<DeleteReasonReducer>) => {
      const data = payload.payload
      modifyBeliefMap(state, data.mapId, (beliefMap) => {
        beliefMap.reasons = beliefMap.reasons.filter((item) => {
          return item.id !== data.reasonId;
        });
      });
    },
    overwriteDraft: (state: BeliefMapState, payload: PayloadAction<{mapId: MapId, beliefMap: BeliefMap | null}>) => {
      const data = payload.payload;
      if (data.beliefMap) {
        state.maps[data.mapId.draftMapId] = data.beliefMap;
      } else {
        delete state.maps[data.mapId.draftMapId];
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadMap.fulfilled, (state, action) => {
        state.maps[action.payload.mapId] = action.payload.response;
      });
  },
});

/**
 * Overwrite the given mapId draft with the given map (or null to delete).
 *
 * This could be done with createAsyncThunk, but then handling my non-redux FlashMessage
 * component would be more difficult.
 */
export async function overwriteDraft(mapId: MapId, beliefMap: BeliefMap | null, dispatch: AppDispatch) {
  await persistDraftMap(mapId, beliefMap);
  dispatch(beliefMapSlice.actions.overwriteDraft({mapId, beliefMap}));
}

/**
 * Publish the given map and return the id of the result.
 */
export async function publishMap(beliefMap: BeliefMap, dispatch: AppDispatch): Promise<string> {
  const newMapId = await publishBeliefMap(serialiseBeliefMap(beliefMap, true));
  // Remove the old draft once we're published
  await overwriteDraft(beliefMap.id, null, dispatch);
  return newMapId;
};

export function useBeliefMap(mapId: MapId) {
  const dispatch = useAppDispatch();
  const maybeInputMap = useAppSelector((state) => selectBeliefMap(state, mapId.inputMapId));
  const maybeBaseMap = useAppSelector((state) => selectBeliefMap(state, mapId.baseMapId));

  if (maybeInputMap === "empty") {
    dispatch(loadMap(mapId.inputMapId));
    return "empty"
  } else if (maybeInputMap === "error" && mapId.inputIsDraft) {
    if (maybeBaseMap === "empty") {
      dispatch(loadMap(mapId.baseMapId));
      return "empty"
    } else if (maybeBaseMap === "error") {
      return "error";
    } else {
      // Not using overwriteDraft() because this avoids the async bit. Also we don't actually
      // need this persisted yet.
      dispatch(beliefMapSlice.actions.overwriteDraft({
        mapId: mapId,
        beliefMap: immerProduce(maybeBaseMap, (beliefMap) => {
          beliefMap.id = mapId;
          return beliefMap;
        })
      }));
      return "empty";
    }
  } else {
    return maybeInputMap;
  }
}


export const { updateBelief, updateReason, deleteReason } = beliefMapSlice.actions
export default beliefMapSlice.reducer;
