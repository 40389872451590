import React from "react";
import { NamedLink } from "../components/namedlink/NamedLink";
import { SerializedBeliefMapv1 } from "../features/beliefMap/beliefMapAPITypes";

export default function HelpPage() {
  return <>
    <h2>Help</h2>
    <p>This application aims to help you clearly communicate the reasons you hold a particular belief.</p>
    <p>A belief and the reasons one might support or reject it are collected into a <i>belief map</i> published at a single URL. Each reason is associated with an icon indicating how much the reason would support or refute the central belief if it were true. A second icon can be used to say whether the map publisher thinks the reason is <i>actually</i> true or false, and how strongly they hold this view. These two icons let you succintly express your position on the full set of arguments people make on a topic. This <NamedLink name="mapPage" params={{mapId: "help-example-icons"}}>example map</NamedLink> shows all the available icons and how they can be interpreted.</p>
    <p>If you're using the application in a dialogue you might want to compare your map with that of your dialogue partner. You might also want to include their reasons in an update to your map so you can say what you think about them. The <NamedLink name="chooseComparePage">compare maps</NamedLink> page can be used for easy comparison and merging of maps.</p>
    <p>When updating maps, note that existing published maps will not be updated or deleted. This is so you can easily view a history of how things have evolved during a dialogue. Your maps will be automatically deleted after 90 days so you don't have to worry about them staying on the internet forever.</p>

    <h3>A user story</h3>
    <p>The following hypothetical scenario illustrates how this application could be useful.</p>
    <p>A small group of friends Alice, Bob, and Carol are discussing nuclear power in their group chat. Alice is saying it should be built immediately, Bob says it isn't safe and he thinks it should be banned. Carol doesn't know what she thinks. </p>
    <p>Carol decides to clarify why the other two hold their beliefs. The central question for her is whether nuclear power is needed in her country (Examplestan). She opens belief mapper and clicks "Create map" then "Edit belief". She puts in "Nuclear power is needed in Examplestan", and publishes the belief to a new URL. Carol copy pastes <NamedLink name="mapPage" params={{mapId: "help-example-initial"}}>the new map URL</NamedLink> into her group chat.</p>
    <p>Carol asks Alice and Bob think of all the reasons they know of that support and refute the proposition and put them in their own versions of the map. They should then each publish their version and share it back to the chat. <NamedLink name="mapPage" params={{mapId: "help-example-alice-1"}}>Alice shares her map</NamedLink> and <NamedLink name="mapPage" params={{mapId: "help-example-bob-1"}}>so does Bob</NamedLink>.</p>
    <p>Carol <NamedLink name="comparePage" params={{baseId: "help-example-alice-1", modId: "help-example-bob-1"}}>compares</NamedLink> the two maps and merges them together (mainly by removing duplicates) to create a <NamedLink name="mapPage" params={{mapId: "help-example-combined-reasons"}}>shared list of reasons</NamedLink> for the participants. She sends this new map to Alice and Bob and gets them to indicate how much each reason supports or refutes the claim and how much they think it's true. <NamedLink name="mapPage" params={{mapId: "help-example-alice-2"}}>Alice</NamedLink> and <NamedLink name="mapPage" params={{mapId: "help-example-bob-2"}}>Bob</NamedLink> submit these maps back to the chat.</p>
    <p>All three participants look at the <NamedLink name="comparePage" params={{baseId: "help-example-alice-2", modId: "help-example-bob-2"}}>comparison page</NamedLink> for the two maps. It is now clear that Alice and Bob agree that proliferation of nuclear technology is a risk for nuclear warfare. Alice however thinks that nuclear power is necessary to provide a reliable low power electricity system.</p>
    <p>The friends now know more clearly what they agree and disagree about and can have a more precise and productive discussion.</p>
  </>;
}

export const helpMaps: {[key: string]: SerializedBeliefMapv1} = {
  "help-example-icons": {
    "version": "1.0",
    "published": "2022-02-09T10:44:50.560Z",
    "data": {
        "title": "Icons example",
        "description": "Belief map demonstrating all the reason icons and what they mean. This part of the page would normally show the main belief that was being described.",
        "reasons": [
            {
                "id": "2c709d8e-b2fe-4d13-a768-dc1f904306bd",
                "title": "Reason would strongly support the main belief, but the publisher hasn't specified whether they believe it is true",
                "belief": null,
                "relevance": "",
                "support": "strong support"
            },
            {
                "id": "921d2663-dd04-4d5a-8f8b-69eee40e0f7c",
                "title": "Publisher strongly believes the reason is true, but they haven't said whether it supports or refutes the main belief",
                "belief": "strong agree",
                "relevance": "",
                "support": null
            },
            {
                "id": "56817396-f141-474c-be5b-5981f95e93fa",
                "title": "The reason would somewhat disconfirm the main belief if it were true, but the publisher somewhat believes it isn't true",
                "belief": "disagree",
                "relevance": "",
                "support": "disconfirm"
            },
            {
                "id": "ea52fec8-b694-400d-83ed-683d69362c12",
                "title": "The publisher is unsure whether the belief is true, but they also think it is irrelevant to the main claim",
                "belief": "neutral",
                "relevance": "",
                "support": "neutral"
            },
            {
                "id": "c8f8bd96-64c7-4ae6-929b-ca75f883e18c",
                "title": "The publisher thinks this reason would somewhat support the claim, and also somewhat thinks it's true",
                "belief": "agree",
                "relevance": "They have also provided some supplementary text describing why they think the reason would somewhat support the claim",
                "support": "support"
            }
        ]
    }
  },
  "help-example-initial": {
    "version": "1.0",
    "published": "2022-02-09T11:06:50.560Z",
    "data": {
        "title": "Nuclear power is needed in Examplestan",
        "description": "",
        "reasons": []
    }
  },
  "help-example-alice-1": {
    "version": "1.0",
    "published": "2022-02-09T11:16:42.863Z",
    "data": {
        "title": "Nuclear power is needed in Examplestan",
        "description": "Alice's list of reasons",
        "reasons": [
            {
                "id": "47c209d8-e990-4424-9421-b4b1bfddb103",
                "title": "Nuclear power can provide reliable low-carbon electricity to support wind and solar",
                "belief": null,
                "relevance": "",
                "support": null
            },
            {
                "id": "add0e320-816b-4654-8a8f-b1cf1a942453",
                "title": "Nuclear power takes a long time to build",
                "belief": null,
                "relevance": "",
                "support": null
            },
            {
                "id": "cb22a14d-37f5-4c06-9cdd-a5768bc2f564",
                "title": "Proliferation of nuclear technology increases the risk of nuclear warfare",
                "belief": null,
                "relevance": "",
                "support": null
            },
            {
                "id": "d2cb6aae-b60e-4e88-ab3b-e65d1d706878",
                "title": "Nuclear power accidents are very dangerous",
                "belief": null,
                "relevance": "",
                "support": null
            }
        ]
    }
  },
  "help-example-bob-1": {
    "version": "1.0",
    "published": "2022-02-09T11:22:11.680Z",
    "data": {
        "title": "Nuclear power is needed in Examplestan",
        "description": "Bob's list of reasons",
        "reasons": [
            {
                "id": "64d3b9b4-a911-4fc6-9fcb-67695183313e",
                "title": "Nuclear power enables development of nuclear weapons by normalising trade in fissile materials",
                "belief": null,
                "relevance": "",
                "support": null
            },
            {
                "id": "9b2e8353-2a82-4802-8e92-68d1135236b4",
                "title": "Nuclear power accidents sometimes happen and have high impact",
                "belief": null,
                "relevance": "",
                "support": null
            },
            {
                "id": "dcd2e49e-40c8-4199-9378-ff4986867fa3",
                "title": "Nuclear power is expensive",
                "belief": null,
                "relevance": "",
                "support": null
            },
            {
                "id": "ea8d3bdd-f1fb-4817-aef9-5ff27c69c51d",
                "title": "VREs need support from more reliable generation methods, nuclear is a good option",
                "belief": null,
                "relevance": "",
                "support": null
            },
            {
                "id": "09e93487-9430-47de-83e7-97b1bdc1cc3f",
                "title": "Nuclear waste remains hazardous for thousands of years",
                "belief": null,
                "relevance": "",
                "support": null
            }
        ]
    }
  },
  "help-example-combined-reasons": {
    "version": "1.0",
    "published": "2022-02-09T11:28:05.253Z",
    "data": {
        "title": "Nuclear power is needed in Examplestan",
        "description": "Shared list of reasons",
        "reasons": [
            {
                "id": "47c209d8-e990-4424-9421-b4b1bfddb103",
                "title": "Nuclear power can provide reliable low-carbon electricity to support wind and solar",
                "relevance": "",
                "belief": null,
                "support": null
            },
            {
                "id": "add0e320-816b-4654-8a8f-b1cf1a942453",
                "title": "Nuclear power takes a long time to build",
                "relevance": "",
                "belief": null,
                "support": null
            },
            {
                "id": "cb22a14d-37f5-4c06-9cdd-a5768bc2f564",
                "title": "Proliferation of nuclear technology increases the risk of nuclear warfare",
                "relevance": "",
                "belief": null,
                "support": null
            },
            {
                "id": "9b2e8353-2a82-4802-8e92-68d1135236b4",
                "title": "Nuclear power accidents sometimes happen and have high impact",
                "relevance": "",
                "belief": null,
                "support": null
            },
            {
                "id": "cb39e50b-0423-4896-899f-f3aad093ae2b",
                "title": "Nuclear power is expensive",
                "belief": null,
                "relevance": "",
                "support": null
            },
            {
                "id": "09e93487-9430-47de-83e7-97b1bdc1cc3f",
                "title": "Nuclear waste remains hazardous for thousands of years",
                "belief": null,
                "relevance": "",
                "support": null
            }
        ]
    }
  },
  "help-example-alice-2": {
    "version": "1.0",
    "published": "2022-02-09T11:38:29.641Z",
    "data": {
        "title": "Nuclear power is needed in Examplestan",
        "description": "Alice's views on the shared list of reasons",
        "reasons": [
            {
                "id": "47c209d8-e990-4424-9421-b4b1bfddb103",
                "title": "Nuclear power can provide reliable low-carbon electricity to support wind and solar",
                "relevance": "",
                "belief": "strong agree",
                "support": "strong support"
            },
            {
                "id": "cb22a14d-37f5-4c06-9cdd-a5768bc2f564",
                "title": "Proliferation of nuclear technology increases the risk of nuclear warfare",
                "relevance": "True, but we're only talking about Examplestan here. We're a stable country with our own Uranium resources.",
                "belief": "agree",
                "support": "disconfirm"
            },
            {
                "id": "9b2e8353-2a82-4802-8e92-68d1135236b4",
                "title": "Nuclear power accidents sometimes happen and have high impact",
                "relevance": "",
                "belief": "disagree",
                "support": "disconfirm"
            },
            {
                "id": "add0e320-816b-4654-8a8f-b1cf1a942453",
                "title": "Nuclear power takes a long time to build",
                "relevance": "If it's the only suitable technology, then we have to use it. We'll get faster as we build more of them.",
                "belief": "agree",
                "support": "neutral"
            },
            {
                "id": "09e93487-9430-47de-83e7-97b1bdc1cc3f",
                "title": "Nuclear waste remains hazardous for thousands of years",
                "relevance": "It's more manageable than plastic pollution",
                "belief": "agree",
                "support": "neutral"
            },
            {
                "id": "cb39e50b-0423-4896-899f-f3aad093ae2b",
                "title": "Nuclear power is expensive",
                "relevance": "Again, if it's the only suitable technology, then we need to use it.",
                "belief": "agree",
                "support": "neutral"
            }
        ]
    }
  },
  "help-example-bob-2": {
    "version": "1.0",
    "published": "2022-02-09T11:44:11.246Z",
    "data": {
        "title": "Nuclear power is needed in Examplestan",
        "description": "Bob's views on the shared list of reasons",
        "reasons": [
            {
                "id": "47c209d8-e990-4424-9421-b4b1bfddb103",
                "title": "Nuclear power can provide reliable low-carbon electricity to support wind and solar",
                "relevance": "I think wind, solar, and battery storage can do fine without it.",
                "belief": "strong agree",
                "support": "support"
            },
            {
                "id": "cb22a14d-37f5-4c06-9cdd-a5768bc2f564",
                "title": "Proliferation of nuclear technology increases the risk of nuclear warfare",
                "relevance": "",
                "belief": "strong agree",
                "support": "strong disconfirm"
            },
            {
                "id": "9b2e8353-2a82-4802-8e92-68d1135236b4",
                "title": "Nuclear power accidents sometimes happen and have high impact",
                "relevance": "They haven't had that high an impact. More than batteries though.",
                "belief": "disagree",
                "support": "disconfirm"
            },
            {
                "id": "cb39e50b-0423-4896-899f-f3aad093ae2b",
                "title": "Nuclear power is expensive",
                "relevance": "",
                "belief": "agree",
                "support": "disconfirm"
            },
            {
                "id": "add0e320-816b-4654-8a8f-b1cf1a942453",
                "title": "Nuclear power takes a long time to build",
                "relevance": "Doesn't really matter if it is necessary.",
                "belief": "agree",
                "support": "neutral"
            },
            {
                "id": "09e93487-9430-47de-83e7-97b1bdc1cc3f",
                "title": "Nuclear waste remains hazardous for thousands of years",
                "relevance": "We have a lot of hazardous waste problems.",
                "belief": "agree",
                "support": "neutral"
            }
        ]
    }
  }
};
