import React, { useEffect, useState, useRef, ReactNode } from "react";
import { Location, Outlet, Link, useNavigate, useLocation, useOutletContext } from "react-router-dom";
import { buildUrl, NamedLink } from "./components/namedlink/NamedLink";
import { useFlashMessage } from "./components/flashmessage/FlashMessage";
import { DEFAULT_MAP_ID } from "./features/beliefMap/beliefMapSlice";
import "./App.css";

interface AppContext {
  setFlashMessage: (message: ReactNode | null, timeout?: number) => void,
  useWindowTitle: (value: string | (() => string)) => void
}

function useColorScheme(): [string, () => void] {
  const [colorschemeLabel, setColorschemeLabel] = useState("");

  const setColorscheme = (darkMode: boolean) => {
    const elm = document.querySelector("html") as HTMLHtmlElement;
    let currClass = elm.getAttribute("class") || "";
    currClass = currClass.replace(/ ?dark-mode/, "");
    if (darkMode) {
      elm.setAttribute("class", currClass + " dark-mode");
      window.localStorage.setItem("colorscheme", "dark");
      setColorschemeLabel("Light mode");
    } else {
      elm.setAttribute("class", currClass);
      window.localStorage.setItem("colorscheme", "light");
      setColorschemeLabel("Dark mode");
    }
  };

  const toggleColorScheme = () => {
    setColorscheme(colorschemeLabel === "Dark mode");
  };

  useEffect(() => {
    if (colorschemeLabel === "") {
      // Just do this when the component's first mounted
      setColorscheme(window.localStorage.getItem("colorscheme") === "dark");
    }
  });

  return [colorschemeLabel, toggleColorScheme];
}

function setWindowTitle(newTitle: string) {
  document.title = newTitle;
}

type ScrollPosStateType = [
  {[key: string]: boolean},
  (newstate: {[key: string]: boolean}) => void
];
function handleScrollPosState(scrollPosState: ScrollPosStateType, location: Location) {
  const [state, setState] = scrollPosState;

  if (state[location.key]) {
    // Browser forward/back button pressed, do nothing
  } else {
    // Navigating via a link or something, reset the scroll position
    window.scrollTo(0, 0);
    state[location.key] = true;
    setState(state);
  }
}

function App() {
  const navigate = useNavigate();
  const location = useLocation() as Location;
  const scrollPosState = useState({});
  const navState = useRef<HTMLInputElement>(null);
  const createMapUrl = buildUrl("mapPage", {mapId: DEFAULT_MAP_ID});

  let titleOverriddenByPage = false;
  const useWindowTitle = (value: string | (() => string)) => {
    const location = useLocation();
    useEffect(() => {
      setWindowTitle(typeof value === "string" ? value : value())
      titleOverriddenByPage = true;
    }, [location]);
  };
  useEffect(() => {
    const shouldRedirect = (location.pathname === "/") || (location.pathname === "/map/");
    if (shouldRedirect) {
      navigate(createMapUrl);
    }

    if (!titleOverriddenByPage) {
      setWindowTitle("Belief Mapper");
    }

    handleScrollPosState(scrollPosState, location);
  }, [location]);
  const {flashMessageElement, setFlashMessage} = useFlashMessage();
  const context = {
    setFlashMessage,
    useWindowTitle
  };

  const [colorschemeLabel, toggleColorScheme] = useColorScheme();

  return (
    <>
      {flashMessageElement}
      <header className="header container">
        <h1>Belief Mapper v0.1</h1>
        <input type="checkbox" className="nav__state" id="nav__state" ref={navState} />
        <nav className="nav__items" onClick={() => {if (navState.current && navState.current.checked) navState.current.checked = false;}}>
          <Link to={createMapUrl}>Create map</Link>
          <NamedLink name="chooseComparePage">Compare maps</NamedLink>
          <NamedLink name="helpPage">Help</NamedLink>
          <button className="button button--link" onClick={toggleColorScheme}>{colorschemeLabel}</button>
        </nav>
        <label htmlFor="nav__state" className="nav__button nav__button--close">&times;</label>
        <label htmlFor="nav__state" className="nav__button nav__button--open">&equiv;</label>
      </header>

      <main className="container">
        <Outlet context={context} />
      </main>
    </>
  );
}

export function useAppContext() {
  return useOutletContext<AppContext>();
}

export default App;
