import React, { useState, useEffect, useRef, FormEvent } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../app/hooks";
import {
  DEFAULT_MAP_ID,
  MapId,
  BeliefMap,
  selectBeliefMap,
  loadMap,
  updateBelief,
  overwriteDraft,
  publishMap,
  parseMapId
} from "../features/beliefMap/beliefMapSlice";
import { NamedLink, buildUrl } from "../components/namedlink/NamedLink";
import ReasonList from "../components/reasonlist/ReasonList";
import SalienceIcon from "../components/salienceicon/SalienceIcon";
import "./BeliefMapPage.css";
import { useAppContext } from "../App";

function buildLoadingResponse() {
  return (
    <h2>Loading map...</h2>
  );
}

type MapResponseProps = {
  mapId: MapId,
  beliefMap: BeliefMap,
  hasDraft: boolean,
  editable: boolean
}

function MapResponse(props: MapResponseProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const appContext = useAppContext();

  const [showEditForm, setShowEditForm] = useState(false);

  const beliefMap = props.beliefMap;
  const mapId = props.mapId;
  const isDefaultMap = mapId.inputMapId === DEFAULT_MAP_ID;

  appContext.useWindowTitle(() => {
    return beliefMap.title + " - Belief Mapper";
  });

  const handleSubmit = (result: false | {title: string, description: string | null}) => {
    setShowEditForm(false);
    if (result) {
      dispatch(updateBelief({
        mapId: mapId,
        title: result.title as string,
        description: result.description
      }));
      if (mapId.inputMapId !== mapId.draftMapId) {
        navigate(buildUrl("mapPage", {mapId: mapId.draftMapId}));
      }
    }
  }
  const handlePublish = async () => {
    appContext.setFlashMessage(<p>Publishing map...</p>)
    try {
      const newMapId = await publishMap(beliefMap, dispatch);
      appContext.setFlashMessage((<p>Redirecting to published map</p>), 2000)
      navigate(buildUrl("mapPage", {mapId: newMapId}));
    } catch (e) {
      console.error(e);
      appContext.setFlashMessage((<p>Error publishing map</p>), 3000)
    }
  };
  const handleDiscard = async () => {
    const handleUndoDiscard = async () => {
      await overwriteDraft(mapId, beliefMap, dispatch);

      navigate(buildUrl("mapPage", {mapId: mapId.draftMapId}));
      appContext.setFlashMessage(null);
    };

    appContext.setFlashMessage(
      (
        <p>
          Draft deleted <button className="button" onClick={handleUndoDiscard}>Undo</button>
        </p>
      ),
      5000
    )
    await overwriteDraft(mapId, null, dispatch);
    if (isDefaultMap) {
      navigate(buildUrl("mapPage", {mapId: DEFAULT_MAP_ID}));
    } else {
      navigate(buildUrl("mapPage", {mapId: mapId.baseMapId}));
    }
  };

  const dateStr = (new Date(beliefMap.updatedAt)).toLocaleString();

  let subTitle = <></>;
  if (mapId.baseMapId === "default") {
    subTitle = (
      <div className="beliefpage-subtitle">You are creating a new belief map from scratch. See the help link in the header if you are having difficulties.</div>
    );
  } else if (mapId.inputIsDraft) {
    subTitle = (
      <div className="beliefpage-subtitle">Draft updated {dateStr}. You can <NamedLink name="mapPage" params={{mapId: mapId.baseMapId}}>view the original map</NamedLink> this draft was derived from. You can <NamedLink name="chooseComparePage" queryParams={{baseMapId: mapId.inputMapId}}>compare this draft map</NamedLink> to another map.</div>
    );
  } else if (props.hasDraft) {
    subTitle = (
      <div className="beliefpage-subtitle">Published {dateStr}. You can <NamedLink name="mapPage" params={{mapId: mapId.draftMapId}}>edit your unpublished draft</NamedLink> based on this map.</div>
    );
  } else {
    subTitle = (
      <div className="beliefpage-subtitle">Published {dateStr}. Share map by copying <NamedLink name="mapPage" params={{mapId: mapId.baseMapId}}>the map URL</NamedLink>. You can <NamedLink name="mapPage" params={{mapId: mapId.draftMapId}}>edit this map</NamedLink> and publish a revised version.</div>
    );
  }

  return (
    <>
      <p className="disclaimer">
        Note that maps published to this system will be automatically deleted after 90 days.
      </p>
      {showEditForm ?
        <MainBeliefForm beliefMap={beliefMap} onSubmit={handleSubmit} />:
        <>
          <h2>{beliefMap.title}</h2>
          {subTitle}
          {beliefMap.description && <p>{beliefMap.description}</p>}
        </>
      }
      {props.editable && !showEditForm && <button className="button" onClick={() => setShowEditForm(true)}>Edit belief</button>}

      <h3>Reasons</h3>
      <ReasonList beliefMap={beliefMap} editable={props.editable} />
      {props.editable && <>
        <h3>Draft actions</h3>
        <p>Once you're ready to share this map with others you can publish it to a new URL.{!isDefaultMap && <> This will not update the map it was based off.</>}</p>
        <div className="beliefpage__actions">
          <button className="button" onClick={handlePublish}>Publish draft</button>
          <button className="button" onClick={handleDiscard}>Discard draft</button>
        </div>
      </>}
    </>
  );
}

type MainBeliefFormProps = {
  onSubmit: (result: {title: string, description: string | null} | false) => void,
  beliefMap: BeliefMap
}
function MainBeliefForm(props: MainBeliefFormProps) {
  const titleRef = useRef<HTMLInputElement>(null);
  const descriptionRef = useRef<HTMLTextAreaElement>(null);
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    props.onSubmit({
      title: titleRef.current?.value as string,
      description: descriptionRef.current?.value || null
    })
    e.preventDefault();
  };

  useEffect(() => {
    if (titleRef.current) {
      titleRef.current.focus();
    }
  });

  return (
    <>
      <form className="form form--stacked" onSubmit={handleSubmit}>
        <label htmlFor="beliefFormTitle">Title:</label>
        <input id="beliefFormTitle" type="text" className="form__input" ref={titleRef} required={true} defaultValue={props.beliefMap.title} />
        <p className="form__longdesc">The one line description of the main belief</p>

        <label htmlFor="beliefFormDescription">Description:</label>
        <textarea id="beliefFormDescription" className="form__input" ref={descriptionRef}  defaultValue={props.beliefMap.description || undefined} />
        <p className="form__longdesc">The description of the main belief.</p>

        <div className="form__actions">
          <button className="button">Update</button>
          <button className="button button--secondary" onClick={(e) => {props.onSubmit(false);e.preventDefault()}}>Cancel</button>
        </div>
      </form>
    </>
  );
}

function buildErrorResponse() {
  return <>
    <h2>Error loading map</h2>
    <p>You might have mispasted the URL, or it might have been deleted.</p>
  </>;
}

interface PageProps {
}

function BeliefMapPage(props: PageProps) {
  const params = useParams();
  const dispatch = useAppDispatch();
  const mapIdStr = params.mapId || "error";
  if (mapIdStr) {
    const mapId = parseMapId(mapIdStr);
    const maybeBaseMap = useAppSelector((state) => selectBeliefMap(state, mapId.baseMapId));
    const maybeDraftMap = useAppSelector((state) => selectBeliefMap(state, mapId.draftMapId));

    if (maybeBaseMap === "empty" || maybeDraftMap === "empty") {
      if (maybeBaseMap === "empty") {
        dispatch(loadMap(mapId.baseMapId));
      }
      if (maybeDraftMap === "empty") {
        dispatch(loadMap(mapId.draftMapId));
      }

      return buildLoadingResponse();
    } else if (maybeBaseMap === "error") {
      return buildErrorResponse();
    } else {
      const targetMap = (mapId.inputIsDraft && maybeDraftMap !== "error") ? maybeDraftMap : maybeBaseMap;
      return <MapResponse mapId={mapId} beliefMap={targetMap as BeliefMap} editable={mapId.inputIsDraft} hasDraft={maybeDraftMap !== "error"} />;
    }
  } else {
    return buildErrorResponse();
  }
}

export default BeliefMapPage;
