import { helpMaps } from "../../pages/HelpPage";
import { SerializedBeliefMapv1 } from "./beliefMapAPITypes";

interface ApiErrorResponse {
  status: "error",
  data: null
};

interface FetchMapApiSuccessResponse {
  status: "ok",
  data: SerializedBeliefMapv1
};

interface PublishMapApiSuccessResponse {
  status: "ok",
  data: {
    mapId: string
  }
};

type FetchMapApiResponse = ApiErrorResponse | FetchMapApiSuccessResponse;
type PublishApiResponse = ApiErrorResponse | PublishMapApiSuccessResponse;

const initialBeliefMap: SerializedBeliefMapv1 = {
  version: "1.0",
  published: "2022-01-26T05:13:01.524Z",
  data: {
    title: "New belief",
    description: "",
    reasons: []
  }
};

export async function loadBeliefMap(id: string): Promise<SerializedBeliefMapv1 | "error"> {
  if (id.startsWith("draft.")) {
    let maybeSerialisedMap;
    if (window.hasOwnProperty("fetchBeliefMapDraft")) {
    // Let people inject their own storage function via e.g. greasemonkey if they want to
      maybeSerialisedMap = await (window as any).fetchBeliefMapDraft(id);
    } else {
      maybeSerialisedMap = window.localStorage.getItem(`map.${id}`);
    }
    if (maybeSerialisedMap) {
      const map = JSON.parse(maybeSerialisedMap);
      if (map.version === "1.0") {
        return map as SerializedBeliefMapv1;
      }
    }

    return "error";
  }

  if (id === "default") {
    return initialBeliefMap;
  } else if (helpMaps[id]) {
    return helpMaps[id];
  }

  // TODO: Actually try to parse this to verify it's OK, the server/user's injected function
  // isn't doing that. See https://github.com/PicnicSupermarket/aegis
  if (window.hasOwnProperty("fetchBeliefMap")) {
    // Let people inject their own storage function via e.g. greasemonkey if they want to
    return await (window as any).fetchBeliefMap(id);
  } else {
    return await fetchBeliefMap(id);
  }
}

async function fetchBeliefMap(id: string): Promise<SerializedBeliefMapv1 | "error"> {
  const url = `/api/map/${id}`;
  const response = await fetch(url);
  const responseObj = await response.json() as FetchMapApiResponse;

  if (responseObj.status === "error") {
    return "error";
  } else {
    return responseObj.data;
  }
}

export async function persistBeliefMapDraft(draftMapId: string, beliefMap: SerializedBeliefMapv1 | null): Promise<undefined> {
  if (window.hasOwnProperty("persistBeliefMapDraft")) {
    // Let people inject their own storage function via e.g. greasemonkey if they want to
    await (window as any).persistBeliefMapDraft(draftMapId, beliefMap);
  } else {
    const key = `map.${draftMapId}`;
    if (beliefMap) {
      window.localStorage.setItem(key, JSON.stringify(beliefMap));
    } else {
      window.localStorage.removeItem(key);
    }

    // Needed for promise to be generated
    return;
  }
}

/**
 * Publishes the given belief map and returns 
 */
export async function publishBeliefMap(beliefMap: SerializedBeliefMapv1): Promise<string> {
  const url = `/api/map/create`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(beliefMap),
  });
  const responseObj = await response.json() as PublishApiResponse;

  if (responseObj.status === "ok") {
    return responseObj.data.mapId;
  } else {
    throw "Error persisting belief map";
  }
}
